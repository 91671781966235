<template>
  <div>
    <div v-if="license">
      <v-row>
        <v-col cols="12" sm="auto">
          <h1 class="text-left">{{ license.name }}</h1>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editLicense" v-if="isAdmin">
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>
          <v-btn :block="$vuetify.breakpoint.mobile" color="error" outlined depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="deleteLicense" v-if="isAdmin">
            <v-icon left>mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table dense class="my-4">
        <tbody>
          <tr>
            <td class="font-weight-bold">Id:</td>
            <td>{{ license.id }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Name:</td>
            <td>{{ license.name }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Pricelist code:</td>
            <td>{{ license.priceListCode }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Currency code:</td>
            <td>{{ license.currencyCode }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Price factor:</td>
            <td>{{ license.priceFactor }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Demonstration license:</td>
            <td>
              <readonly-checkbox :value="license.isDemonstrationLicense" />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Enabled:</td>
            <td>
              <readonly-checkbox :value="!license.disabled" />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Last updated:</td>
            <td><last-updated-field :item="license" /></td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="mt-8">
        <h3>Companies</h3>
        <v-data-table :headers="companyHeaders" :items="license.companies" v-bind="$util.dataTableAttributes()">
          <template #[`item.name`]="{ item }">
            <router-link :to="{ name: 'CompanyDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
          </template>
          <template #[`item.disabled`]="{ item }">
            <readonly-checkbox :value="!item.disabled" />
          </template>
        </v-data-table>
      </div>

      <div class="mt-8">
        <h3>Configurations assigned to license</h3>
        <v-data-table
          v-bind="$util.dataTableAttributes()"
          :headers="[
            {
              text: 'Name',
              value: 'configurationName',
            },
            {
              text: 'Enabled',
              value: 'disabled',
            },
          ]"
          :items="license.configurations"
        >
          <template #[`item.configurationName`]="{ item }">
            <router-link v-if="isAdmin" :to="{ name: 'ConfigurationDetails', params: { Id: item.id } }">{{ item.configurationName }}</router-link>
            <span v-else>{{ item.configurationName }}</span>
          </template>
          <template #[`item.disabled`]="{ item }">
            <readonly-checkbox :value="!item.disabled" />
          </template>
        </v-data-table>
      </div>
      <create-edit-license ref="CreateEditLicenseRef" @license-updated="bubbleEmit" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService';
import CreateEditLicense from '../Dialogs/CreateEditLicense.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';

export default {
  components: { CreateEditLicense, ReadonlyCheckbox },
  name: 'licenseDetails',
  data: () => ({
    loading: true,
    license: {},
    subscriptions: [],
    companyHeaders: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Department',
        value: 'department',
      },
      {
        text: 'Country',
        value: 'country',
      },
      {
        text: 'Account number',
        value: 'accountNumber',
      },
      {
        text: 'Enabled',
        value: 'disabled',
      },
      {
        text: 'Users',
        value: 'usersCount',
      },
    ],
  }),
  computed: {
    ...mapGetters(['isAdmin'])
  },
  methods: {
    async getLicense() {
      this.loading = true;
      this.license = await apiService.getLicenseById(this.$route.params.Id);
      this.loading = false;
    },
    editLicense() {
      this.$refs.CreateEditLicenseRef.editLicense(this.license);
    },
    bubbleEmit() {
      this.getLicense().then(() => {
        this.$emit('license-updated');
      });
    },
    async deleteLicense() {
      if (this.license.companies.length) {
        alert(`Cannot delete license as it is assigned to ${this.license.companies.length} companies. The companies must be assigned another license before this license can be deleted.`);
      } else {
        if (confirm('Are you sure you want to delete this license? This cannot be undone.')) {
          await apiService.deleteLicense(this.license.id);
          this.$router.back();
        }
      }
    },
  },
  created() {
    this.getLicense();
  },
};
</script>

<style></style>
